@import url("https://fonts.googleapis.com/css2?family=Source+Code+Pro&display=swap");
body {
  margin: 0;
  font-family: "Source Code Pro", monospace;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

div {
  font-size: 14pt;
}

h1 {
  font-size: 4rem;
}

h2 {
  align-self: center;
}
