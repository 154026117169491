.screenLayout {
  height: 100vh;
  padding: 48px;
}

@media (max-width: 1200px) {
  .screenLayout {
    padding: 48px;
  }
}
